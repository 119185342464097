@font-face {
  font-family: promptLight;
  src: url(fonts/Prompt-Light.ttf);
  font-style: normal;
}

@font-face {
  font-family: cinzel;
  src: url(fonts/Cinzel-Regular.ttf);
  font-style: normal;
}

body {
  margin: 0;
  font-family: promptLight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: promptLight,
    monospace;
}
